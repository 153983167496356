/** @jsx jsx */
import { memo } from "react";
import { jsx, SxStyleProp } from "theme-ui";

import Container from "../Container";

const Section: React.FC<{
  background?: React.ReactNode;
  backgroundPosition?: SxStyleProp;
  children: React.ReactNode;
  theme?: string;
  customsx?: SxStyleProp;
}> = ({
  background,
  backgroundPosition = { right: 0, bottom: 0 },
  children,
  theme,
  customsx,
}) => (
  <section
    sx={{
      backgroundColor: theme || "background",
      color: theme === "primary" ? "#fff" : undefined,
      paddingBottom: [4, 6, 6, 7],
      position: "relative",
      paddingTop: [4, 6, 6, 7],
      ...customsx,
    }}
  >
    {background && (
      <div sx={{ position: "absolute", ...backgroundPosition }}>
        {background}
      </div>
    )}
    <div sx={{ position: "relative" }}>
      <Container>{children}</Container>
    </div>
  </section>
);

export default memo(Section);
