/** @jsx jsx */
import "../fonts/stylesheet.css";

import React, { memo } from "react";
import { jsx, ThemeProvider } from "theme-ui";

import AnimalBadges from "../components/AnimalBadges";
import Container from "../components/Container";
import Footer from "../components/Footer";
import Heading from "../components/Heading";
import MainMenu from "../components/MainMenu";
import Section from "../components/Section";
import themeAquatic from "../theme-aquatic";

const BrokenPage: React.FC<Record<string, unknown>> = () => (
  <ThemeProvider theme={themeAquatic}>
    <div sx={{ backgroundColor: "primary" }}>
      <Container>
        <MainMenu />
      </Container>
    </div>
    <Section customsx={{ backgroundColor: "muted" }}>
      <Heading>{"Oups, la page que vous cherchez est introuvable"}</Heading>
      <AnimalBadges />
    </Section>
    <Footer isTerrestrial={false} />
  </ThemeProvider>
);

export default memo(BrokenPage);
