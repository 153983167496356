/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */
import { graphql, Link, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { memo } from "react";
import { jsx, Styled } from "theme-ui";

import { FooterQuery } from "../../../__generated__/queries";

const Footer: React.FC<{ isTerrestrial?: boolean }> = ({
  isTerrestrial = true,
}) => {
  const data = useStaticQuery<FooterQuery>(graphql`
    query FooterQuery {
      terrestrialFooter: file(relativePath: { eq: "terrestrial-footer.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aquaticFooter: file(relativePath: { eq: "aquatic-footer.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMarkdownRemark(
        filter: { fields: { sourceName: { eq: "pages" } } }
        sort: { fields: frontmatter___page_weight, order: ASC }
      ) {
        nodes {
          frontmatter {
            url
            page_name
          }
        }
      }
    }
  `);

  const imageData = (isTerrestrial
    ? data.terrestrialFooter?.childImageSharp?.fluid
    : data.aquaticFooter?.childImageSharp?.fluid) as FluidObject;

  return (
    <footer sx={{ position: "relative" }}>
      {imageData && (
        <div>
          <Img fluid={imageData} />
        </div>
      )}
      <div
        sx={{
          position: "absolute",
          bottom: [3, 5],
          left: 0,
          right: 0,
        }}
      >
        <Styled.ul
          sx={{
            display: ["block", "flex"],
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          {data.allMarkdownRemark.nodes.map((node, index) => (
            <li
              key={index}
              sx={{
                color: "white",
                fontSize: 1,
                ml: [0, 5],
                textTransform: "uppercase",
                ":first-of-type": {
                  ml: 0,
                },
              }}
            >
              <Styled.a
                as={Link}
                sx={{ color: "white", border: "none" }}
                // @ts-ignore
                to={node.frontmatter?.url ? `/${node.frontmatter?.url}` : "/"}
              >
                {node.frontmatter?.page_name}
              </Styled.a>
            </li>
          ))}
        </Styled.ul>
      </div>
    </footer>
  );
};

export default memo(Footer);
