/** @jsx jsx */
import { memo } from "react";
import { jsx, SxStyleProp } from "theme-ui";

const Heading: React.FC<{
  children: React.ReactNode;
  customsx?: SxStyleProp;
  hook?: string | null;
}> = ({ children = "", hook, customsx = {} }) => (
  <div
    sx={{
      color: "primary",
      fontFamily: "heading",
      fontSize: [5, 5, 5, 6],
      lineHeight: "heading",
      marginBottom: [4, 4, 4, 4, 5],
      ...customsx,
    }}
  >
    {hook ? (
      <div
        sx={{
          color: "accent",
          fontFamily: "body",
          fontSize: [0, 0, 1],
          letterSpacing: "4px",
          lineHeight: "body",
          marginBottom: 3,
          paddingLeft: "3ex",
          position: "relative",
          textTransform: "uppercase",
          "::before": {
            color: "accent",
            content: '"—"',
            left: 0,
            marginRight: ".75ex",
            position: "absolute",
          },
        }}
      >
        {hook}
      </div>
    ) : null}
    <h1
      sx={{
        fontSize: "inherit",
        fontFamily: "inherit",
        fontWeight: "inherit",
        lineHeight: "inherit",
        color: "inherit",
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      {children}
    </h1>
  </div>
);

export default memo(Heading);
